/* src/Wheel.css */
button:focus, input:focus {outline: none;box-shadow: none;}
a, a:hover {text-decoration: none;}

.wheel-spin-box2 {position: relative;display: inline-block;}
#spinwheel {
  position: relative;
  width: 360px;
  margin: auto;}

.unpaid-spin .wheeldotsround {
   position: absolute;
   z-index: 5;
   width: 100%;
   height: 100%;
   left: auto;
   top: auto;
  /* left:  134px;
  top:140px; */
}
.unpaid-spin .wheeldotsround .rotated-divReward{
   position: absolute;  
   left:130px;
  top:138px;
}

.unpaid-spin .wheeldotsround .rotated-textReward 
{
  position: absolute;  
  z-index: 5;
}

  .rotated-textReward:nth-child(1) {
  transform: rotate(23deg) translateX(140px) rotate(88deg);
}

.rotated-textReward:nth-child(2) {
  transform: rotate(65deg) translateX(140px) rotate(88deg);
}

.rotated-textReward:nth-child(3) {
  transform: rotate(113deg) translateX(140px) rotate(88deg);
}

.rotated-textReward:nth-child(4) {
  transform: rotate(158deg) translateX(140px) rotate(88deg);
}

.rotated-textReward:nth-child(5) {
  transform: rotate(203deg) translateX(140px) rotate(88deg);
}

.rotated-textReward:nth-child(6) {
  transform: rotate(247deg) translateX(140px) rotate(88deg);
}

.rotated-textReward:nth-child(7) {
  transform: rotate(293deg) translateX(140px) rotate(88deg);
}

.rotated-textReward:nth-child(8) {
  transform: rotate(335deg) translateX(140px) rotate(88deg);
}

#spinwheel {position: relative;width: 360px;margin: auto;}
.unpaid-spin #spinwheel svg {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: #160133;
  background: rgb(22, 1, 51);
  background: linear-gradient(200deg, rgb(81 36 144) 0%, rgb(100 75 134) 40%, rgb(145 119 181) 100%);
  padding: 20px;
  position: relative;
}
.wheel-text123{
  font-size: 35px;
  font-family:Arial, sans-serif;  
}
.wheel-spin-box2 {position: relative;border: 14px solid #c1b8ff;border-radius: 9999px;padding: 10px;}

.wheel-spin-box2 .spin-click-button {
  position: absolute;top: 50%;transform: translateY(-50%);z-index: 5;border-radius: 9999px;width: 60px;height: 60px;white-space: nowrap;background: #ffffff;color: #160133;font-size: 14px;padding: 0px;display: flex;align-items: center;justify-content: center;margin: 0 auto;left: 0px;right: 0px;box-shadow: 0px 0px 9px 1px #000000;
  transition: transform .3s ease;
}

.wheel-spin-box2 .chartholder .slice path {fill: #5e4be2;}
.wheel-spin-box2 .chartholder .slice:nth-child(2n+1) path {fill: #160133;}
.wheel-spin-box2 .chartholder .slice:nth-child(2n+1) path {fill: #160133;}
.unpaid-spin .wheel-spin-arrow {position: absolute;text-align: center;left: 0px;right: 0px;margin: 0px auto;top: -12px;z-index: 8;}
.unpaid-spin .wheel-spin-arrow svg {max-width: 65px;}
.unpaid-spin.spin-click-button {background-color: #000;font-size: 14px;font-weight: 600;color: #fff;border: none;padding: 14px 35px;border-radius: 15px;}
.modal-content #card-animation {pointer-events: none;}
.vote-result-model .modal-body, .vote-result-model .modal-footer {z-index: 2;}


/* .wheel-spin-box .spin-click-button:hover */
.wheel-spin-box2 .spinroundfree {
background-size: 600% 620%;
transform: translateY(-50%) scale(1.1) rotate(360deg) !important;
font-size: 16px;
}


@media (max-width: 479.98px) {
  #spinwheel {
    width: 270px;
  }
/* .unpaid-spin .wheeldotsround { 
  
} */

.unpaid-spin .wheeldotsround .rotated-divReward{
   position: absolute;  
   left: 115px;
  top:123px ;
}

.rotated-textReward:nth-child(1) {
  transform: rotate(23deg) translateX(124px) rotate(88deg) !important;
}

.rotated-textReward:nth-child(2) {
  transform: rotate(65deg) translateX(124px) rotate(88deg) !important;
}

.rotated-textReward:nth-child(3) {
  transform: rotate(113deg) translateX(124px) rotate(88deg) !important;
}

.rotated-textReward:nth-child(4) {
  transform: rotate(158deg) translateX(124px) rotate(88deg) !important;
}

.rotated-textReward:nth-child(5) {
  transform: rotate(203deg) translateX(124px) rotate(88deg) !important;
}

.rotated-textReward:nth-child(6) {
  transform: rotate(247deg) translateX(124px) rotate(88deg) !important;
}

.rotated-textReward:nth-child(7) {
  transform: rotate(293deg) translateX(124px) rotate(88deg) !important;
}

.rotated-textReward:nth-child(8) {
  transform: rotate(335deg) translateX(124px) rotate(88deg) !important;
}


}

/* Wheel Animation applied with wheen spin */
/* .wheel-spin-box2:hover {
  animation: sizeIncrease 0.7s ease-in-out forwards;
} */

/* Alternative way with keyframes */
@keyframes sizeIncrease {
  from {
    transform: scale(1);
  }
  to {
    transform: scale(1.1);
  }
}