.RoundBox{    
    
    /* border: 1px solid red; */
    border: 1px solid;
    border-image-source: linear-gradient(138.35deg, #F6C70F 2.76%, rgba(246, 199, 15, 0.25) 28.99%, rgba(246, 199, 15, 0.25) 69.52%, #F6C70F 98.13%);     
    border-image-slice: 1;
    border-radius: 10px;
    width: 165px;
    height: 210px;        
    display: flex;
    justify-content: start;
    align-items: center;
    flex-direction: column;
    padding: 20px;
    background: #2E1B4880;
    margin: 10px 0px;

}
.RoundBox span{
    font-family: Poppins;
    font-size: 10px;
    font-weight: 400;
    line-height: 15px;
    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
}
.RoundBox p{
    font-family: Poppins;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;  
    margin-bottom: 10px;  
}

.LogoDiv{
    justify-content: center;
    align-items: center;
    display: flex;
    width: 75px;
    height: 75px;
    border-radius: 50px;
    /* border: 1px solid red; */
    background: #160133;
    box-shadow: 1px 1px 1px 0px #AAAAAA40 inset;
    box-shadow: -1px -1px 0px 0px #00000040 inset;
    margin-bottom:10px ;
    margin-top: -10px;
}
.LogoDiv img{
    width: 50px;
    height: 50px;
}

.TopImageBox{
width: 209px;
height: 177px;
position: relative;
border: 10px;
display: flex;
justify-content: end;
flex-direction: column;
align-items: center;
margin:10px 20px;
}
.BoxFoxImage{
position:absolute;
/* border: 1px solid red !important; */
width: 100%;
top: 0;
z-index: 2;
}

.BoxFoxImage img{
    width: 50px;
    height: 50px;
}

.ShadowBox{
    width: 0px;
height: 0px;
backdrop-filter: blur(90px);
margin: 0 auto;
border-radius:50px ;
         
border-radius: 50%;
}

.BoxForText{
    height: 150px;
    width: 100%;    
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background: #2E1B484D;
    border-radius: 10px;
    border: 1px solid;
    border-image-source: linear-gradient(120.5deg, rgba(255, 255, 255, 0.5) 2.05%, rgba(255, 255, 255, 0) 98.2%),
    linear-gradient(120.5deg, rgba(255, 255, 255, 0) 2.05%, rgba(255, 255, 255, 0.1) 98.2%);
    border-image-slice: 1;
    backdrop-filter: blur(5px);
    padding: 10px 10px;
    z-index: 1;
}

.BoxForText p{
    font-family: Poppins;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
}

.BoxForText p{
    font-family: Poppins;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none; 
    margin: 15px 0px 10px 0px;
}

.BoxForText span{
    font-family: Poppins;
    font-size: 10px;
    font-weight: 400;
    line-height: 15px;
    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    margin: 0px 15px;
}