/* .slick-prev:before,
.slick-next:before {
  background: red;
}

.HelloSlide{
  border: 1px solid red;
} */
.goldenBorder-box{
  margin: 0px -15px;
}
.goldenBorder-box .carousel-inner{
  padding: 0px 15px;
}

.goldenBorder-box-pair{
  margin: 0px -20px;
}
.goldenBorder-box-pair .carousel-inner{
  padding: 0px 25px;
}

.goldenBorder-box-pair .carousel-inner .carousel-item .calIte{
  min-width: 100% !important;
  width: 100%;
}


@media (max-width: 768px) { /* Target screens smaller than 768px (mobile view) */
  .goldenBorder-box-pair .carousel-inner .carousel-item .calIte{
    min-width: 250px !important;
    width: 250px;
  } 
}