.GoldenVoteText{
    position: absolute;
    top: -55px;
    color: white;
    width: 100%;
    font-size: 25px;
    font-weight: 800;
}

.GoldenVoteText p{
    text-shadow: 
    -1px -1px 0 goldenrod,
     1px -1px 0 goldenrod,
    -1px  1px 0 goldenrod,
     1px  1px 0 goldenrod;
}