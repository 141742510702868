.blink-button {
  position: relative;
  border-radius: 5px;
}
.blink-button .inner-btn {
  position: relative;
  z-index: 2;
}
.blink-button:after {
  background-color: rgb(198 192 245);
  content: "";
  display: block;
  width: calc(100% + 4px);
  height: calc(100% + 2px);
  position: absolute;
  top: 0px;
  left: -2px;
  border-radius: 3px;
  pointer-events: none;
  z-index: 1;
  -webkit-animation-name: blink;
  animation-name: blink;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  animation-direction: alternate-reverse;
  -webkit-animation-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
  animation-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
}
@keyframes blink {
  0% {
    transform:scale3d(1,1,1);
    opacity: 0.8;
  }
  100% {
    transform:scale3d(1.1,1.3,1.1);
    opacity:0;
  }
}