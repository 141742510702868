.CoinBox{
  min-width: 100px;
  border: 1.5px solid #DAA636;
  color: #DAA636;
  width: 100%;
  max-width: 130px;
  text-align: center;
  border-radius:20px;
  display: flex;
  justify-content: start;
  align-items: center;
  line-height: 13px;
  padding: 0px 15px;
  height: 35px;
}
.selectCoinBox{
  background: 
    linear-gradient(to bottom,#F4F6FA,#C6D1E3) padding-box, /*this is your grey background*/
    linear-gradient(to bottom, #5263B8, #B266F5) border-box;
  color: linear-gradient(to bottom, #5263B8, #B266F5);
  min-width: 100px;
  /* padding: 10px; */
  border: 2px solid transparent;
  border-radius: 20px;
  display: inline-block;
  /* margin: 75px 0; */
  width: 100%;
  max-width: 130px;
  text-align: center;
  display: flex;
  justify-content: start;
  align-items: center;
  line-height: 13px;
  padding: 0px 15px;
  height: 35px;

}
.selectCoinBox > div > span {
  background: -webkit-linear-gradient(#5263B8, #B266F5);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.blurbutton{  
  display: flex;
  justify-content: space-between;
  flex: wrap;
  flex-direction: column;
  border: 1px solid;
  border-radius: 10px;
                      /* // width: `${window.screen.width < 767 ? "98%" : payamount < 10 ? "60%" : "48%"}`, */
  width: 100%;
  margin-bottom: 10px;
  background: #281764fa;
  padding: 10px 40px;
}