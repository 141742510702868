.containerValueDot {      
  /* padding: 20px 0px;   */
  width: 100%;
  /* margin: 0 auto;   */
  /* left: -18px; */

}
.FullbarimpactDot{
display: flex;
height: 48px;
  align-items: center;
  justify-content: space-between;
  /* position: absolute; */
  left: 0px;
  top: 0px;  
  /* background: transparent linear-gradient(180deg, #6352e8 0%, #3712b3 100%) 0% 0% no-repeat padding-box; */
}
.boxesdot {
  z-index: 1;
  position: relative;
  padding: 1px;
  display: flex;
  width: 26.5%;
  height: 29px;
  background: transparent linear-gradient(180deg, #6352e8 0%, #3712b3 100%) 0% 0% no-repeat padding-box;
  /* gap: 1px; */
  /* background: transparent linear-gradient(180deg, #6352e8 0%, #3712b3 100%) 0% 0% no-repeat padding-box; */  
}
.backGroundBoxDot{
 position:relative;
 display: flex;
 width: 100%;
 gap: 1.5px;
 border-radius: 10px 0px 0px 10px;
 background: linear-gradient(273deg, rgba(171,148,255,1) 0%, rgba(79,69,179,1) 70%, rgba(9,9,121,1) 100%);
 overflow: hidden;
}

.whiteBox{
position: absolute;
  top: 0;
  left: 0;   
  height: 100%;  
  background-color: white;
  z-index: 1;
}

.backGroundBoxDot2{
  position:relative;
 display: flex;
 width: 100%;
 gap: 1.5px;
 border-radius: 0px 10px 10px 0px;
 overflow: hidden;
 background: linear-gradient(90deg, rgba(171, 148, 255, 1) 0%, rgba(79, 69, 179, 1) 70%, rgba(9, 9, 121, 1) 100%);
}
.whiteBox2{
position: absolute;
  top: 0;
  /* left: 0;   */
  right: 0;
  height: 100%;
  /* border-radius: 0px 10px 10px 0px; */
  transition: 'width 0.5s ease';
  background-color: white; /* Your desired background color */
  z-index: 1; /* Ensures it's on top */
}

.boxchilddot {  
  background-color: #ffffff;
  border: 1.5px solid #ffffff;  
  border-radius: 4px;
  z-index: 2;
    width: 20%; 
    /* height: 30px; */
    background: none;
}

.centerBoxdot {
  z-index: 2;
  width: 45%;
  display: flex;
  align-items: center;
  justify-content: center; 
  /* border: 1px solid #4b3dff; */
  border-radius: 2px;
  /* height: 48px;  */
  /* background: transparent linear-gradient(180deg, #6352e8 0%, #3712b3 100%) 0% 0% no-repeat padding-box; */
  /* background:#4b3dff; */
}

.teamdot {  
  background-color: #ffffff;
  padding: 13px;
  /* border-radius: 2px; */
  font-weight: bold;
  margin: 0px 2px;
}

/* .vsdot {  
  background-color: #ffffff;
  padding: 5px;
  border-radius: 2px;
  font-weight: bold;
  font-size: 1.5rem;
  color: #4a3dff;
} */

.vsdot{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.vsdot1 {  
      margin-top: -8px;
    width: 1px;
    height: 13px;
    /* height: 6px; */
    background: #4b3dff;
}
.vsdot2 {  
  width: 10px;
    height: 1px;
    background: #4b3dff;  
}
.vsdot3 {      
  padding: 1px 12px;
    border-radius: 2px;
    font-weight: bold;
    font-size: 1rem;
    color: #4b3dff;
}



.SVItext{
  display: flex;
  justify-content: space-between;
  padding: 0px 10px;
  margin-top: -5px;
  color: #6352e9;
}
.shap-boxdot{
  width: 100%;
  display: flex ;
  position: relative;
  height: 100% 
  }
  .shap-boxdot2{
    width: 100%;
    display: flex ;
    position: relative;
    height: 100% 
  }

  .round-divdot{
    z-index: 3;
    border-radius: 70% 0% 0% 70%;
    width: 7px;  
    position: absolute;
    height: 100% ;
    right: 0px;
    }    


@media (max-width: 767px) {
  /* .boxchild {  
    width: 20px; 
    height: 20px; 
  } */

  .teamdot{
    padding: 8px;
  }  
   .vsdot{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.vsdot1 {      
    height: 11px;  
      margin-top: -6px;        
}
.vsdot3 {     
  padding: 1px 8px;
  font-size: 0.7rem;
}
  .centerBoxdot{
    /* height: 40px; */
    /* width: 36%; */
  }
}