/** @format */

.card.legendary {
    /* border: #f5e7b5 solid 8px; */
    border-image: linear-gradient(180deg, #FFD25A 0.05%, #F89E18 51.39%, #FFD25A 99.94%) 30; 
      border-width: 8px;
      border-style: solid;     
  }
  .card.common {
    /* border: #d4d0f3 solid 8px; */
    border-image: linear-gradient(180deg, #D4D1F2 0.05%, #FFF 60.39%, #D4D1F2 99.94%) 30;     
      border-width: 8px;
      border-style: solid;    
  }
  .card.epic {
    /* border: #6352e8 solid 8px; */
    border-image:linear-gradient(180deg, #6250EE 0.05%, #D5ABFF 51.39%, #6250EE 99.94%) 30;     
      border-width: 8px;
      border-style: solid;   
  }
  .card.rare {
    /* border: #d2d2d2 solid 8px; */
    border-image: linear-gradient(180deg, #B0B0B0 0.05%, #FFF 51.39%, #B0B0B0 99.94%) 30;     
      border-width: 8px;
      border-style: solid;        
  }
  .card.uncommon {
    /* border: #d4d0f3 solid 8px; */
    border-image: linear-gradient(180deg, #D4D1F2 0.05%, #FFF 60.39%, #D4D1F2 99.94%) 30;         
      border-width: 8px;
      border-style: solid;    
  }
  /* .legendary_text {
    border: #f5e7b5 solid 6px;
    color: #160133;
    border-radius: 0px 0px 8px 8px;
    background-color: #f5e7b5;
    text-transform: uppercase;
    font-size: 25px;
    line-height: 25px;
    font-weight: bold;  
  } */
  /* .common_text {
    border: #d4d0f3 solid 6px;
    color: #160133;
  
    border-radius: 0px 0px 8px 8px;
    background-color: #d4d0f3;
    text-transform: uppercase;
    font-size: 25px;
    line-height: 25px;
    font-weight: bold;  
  } */
  /* .epic_text {
    border: #6352e8 solid 6px;
    color: #160133;
  
    border-radius: 0px 0px 8px 8px;
    background-color: #6352e8;
    text-transform: uppercase;
    font-size: 25px;
    line-height: 25px;
    font-weight: bold;
  } */
  /* .rare_text {  
    color: #160133;
  
    border-radius: 0px 0px 8px 8px;
    background-color: #d2d2d2;
    text-transform: uppercase;
    font-size: 25px;
    line-height: 25px;
    font-weight: bold;
    
  } */
  /* .uncommon_text {  
    text-transform: uppercase;
    color: white;
    font-size: 25px;
    line-height: 25px;
    font-weight: bold;
  } */
  
  .legendary_text {
    border: #f5e7b5 solid 6px;
    color: #160133;
    border-radius: 0px 0px 8px 8px;
    background-color: #f5e7b5;
    text-transform: uppercase;
    font-size: 14px;
    line-height: 14px;
    font-weight: bold;
    top: 10px;
  }
  .common_text {
    border: #d4d0f3 solid 6px;
    color: #160133;
  
    border-radius: 0px 0px 8px 8px;
    background-color: #d4d0f3;
    text-transform: uppercase;
    font-size: 14px;
    line-height: 14px;
    font-weight: bold;
    top: 10px;
  }
  .epic_text {
    border: #6352e8 solid 6px;
    color: #160133;
  
    border-radius: 0px 0px 8px 8px;
    background-color: #6352e8;
    text-transform: uppercase;
    font-size: 14px;
    line-height: 14px;
    font-weight: bold;
    top: 10px;
  }
  .rare_text {
    border: #d2d2d2 solid 6px;
    color: #160133;
  
    border-radius: 0px 0px 8px 8px;
    background-color: #d2d2d2;
    text-transform: uppercase;
    font-size: 14px;
    line-height: 14px;
    font-weight: bold;
    top: 10px;
  }
  .uncommon_text {
    border: #d4d0f3 solid 6px;
    color: #160133;
  
    border-radius: 0px 0px 8px 8px;
    background-color: #d4d0f3;
    text-transform: uppercase;
    font-size: 14px;
    line-height: 14px;
    font-weight: bold;
    top: 10px;
  }
  
  
  
  
  
  
  
  
  .class_text{
  text-transform: uppercase;
    color: white;
    font-size: 15px;
    line-height: 15px;
    font-weight: bold;
  }
  
  .legendary {
    background-image: url("../../assets/images/legendary.png") !important;
    background-size: contain;
  }
  .rare {
    background-image: url("../../assets/images/rare.png") !important;
    background-size: contain;
  }
  .common {
    background-image: url("../../assets/images/common.png") !important;
    background-size: contain;
  }
  .epic {
    background-image: url("../../assets/images/epic.png") !important;
    background-size: contain;
  }
  .uncommon {
    background-image: url("../../assets/images/uncommon.png") !important;
    background-size: contain;
  }
  .card {
    width: 252px;
    height: 320px;
    /* width: 100%;
    height: auto; */
    text-align: center;
  }
  
  .shadow {
    box-shadow: 0 0.5rem 1rem rgba(var(--bs-body-color-rgb), 0.15) !important;
  }
  
  .card {
    --bs-card-spacer-y: 1rem;
    --bs-card-spacer-x: 1rem;
    --bs-card-title-spacer-y: 0.5rem;
    --bs-card-title-color: ;
    --bs-card-subtitle-color: ;
    --bs-card-border-width: var(--bs-border-width);
    --bs-card-border-color: var(--bs-border-color-translucent);
    --bs-card-border-radius: var(--bs-border-radius);
    --bs-card-box-shadow: ;
    --bs-card-inner-border-radius: calc(
      var(--bs-border-radius) - (var(--bs-border-width))
    );
    --bs-card-cap-padding-y: 0.5rem;
    --bs-card-cap-padding-x: 1rem;
    --bs-card-cap-bg: rgba(var(--bs-body-color-rgb), 0.03);
    --bs-card-cap-color: ;
    --bs-card-height: ;
    --bs-card-color: ;
    --bs-card-bg: var(--bs-body-bg);
    --bs-card-img-overlay-padding: 1rem;
    --bs-card-group-margin: 0.75rem;
    word-wrap: break-word;
  }
  
  .lege_text {
    color: #160133;
    border: #f5e7b5 solid 6px;
    border-radius: 0px 0px 8px 8px;
    background-color: #f5e7b5;
    text-transform: uppercase;
    font-size: 14px;
    line-height: 14px;
    font-weight: bold;
    top: 10px;
  }
  
  .cardname {
    /* font-size: 30px;
    color: #160133;
    text-align: center; */
    font-size: 19px;
    color: #160133;
    text-align: center;
    margin-top: 10px;
    display: block;
  }
  
  .card-body {
    width: 100%;
    flex: 1 1 auto;
    padding: var(--bs-card-spacer-y) var(--bs-card-spacer-x);
    color: var(--bs-card-color);
  }
  
  .img-fluid {
    max-width: 100%;
    height: auto;
  }
  .rotateY360 {
    animation: spin 2s linear infinite;
  }