.upgradepagesec{
    width: 900px;
    margin: 0 auto;
}
.Homeimage{
    width: 100%;   
    position: relative; 
}

.cmpboxIframe{
    width: 100%;
    position: absolute;
    top: 70px;
    left: 0px;
    display: flex;
    justify-content: center;
}
.cmpboxIframeInside{
    width: 70%;
    display: flex;
    justify-content: center;
}
.cmpboxIframeInside iframe{
    width: 225px;
}
.Homeimage .homeinimage{
    width: 900px;
}

.Homeimage p{
    font-size: 1.5rem;
    font-weight: 400;     
}
.Homeimage .spantextDiv{
    font-size: 17px;
    font-weight: 200;
   line-height: 34px;
   /* font-family: system-ui; */
}
.calculatorBox{
    margin:0 auto ;
    /* margin-top: 50px; */
    margin-bottom: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;    
}
.calculatorBox p{
    font-size: 20px;
    text-align: center;
}
.payButtonDiv{
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
flex-wrap: wrap;
}
.PayButton{
    background-color: #f8c912;
    color: white;
    border: none;
    padding: 10px 5px;
    width: 170px;
    border-radius: 50px;
    border-radius: 50px;
    cursor: pointer;
    font-size: 15px;
    font-weight: 700;
}
.PayButton .amounttext{
color:#7D58CD;
}

.PayButton .vtetext{
color: Black;
}
.imageBoxDiv{
    display: flex;
    justify-content: center;
    /* align-items: center; */
    flex-wrap: wrap;
    margin-top: 50px;
    margin-bottom: 30px;
}

.imageBoxDiv .TextStroeDiv{
    width: 50%;
    padding: 10px 20px;
}
.imageBoxDiv .TextStroeDiv p{
     margin: 20px 0px;
     text-align: center;
     font-size: 20px;
}
.imageBoxDiv .TextStroeDiv span{
     font-size: 17px;
    font-weight: 200;
   line-height: 32px;
   /* font-family: system-ui; */
}

.imageBoxDiv .ImgStroeDiv{
    width: 50%;
    border: 1px solid #525252;
    border-radius:20px;
    overflow: hidden;
}
.imageBoxDiv .ImgStroeDiv img{
    width: 100%;
    height: 100%;
}
.upgradeLastDiv{
    margin-top:80px;
    margin-bottom:50px;
    /* text-align: center; */
}
.upgradeLastDiv p{
    font-size: 30px;
    margin-bottom: 15px;
    text-align: center;
}
.upgradeLastDiv span{
     font-size: 17px;
    font-weight: 200;
   line-height: 34px;
   /* font-family: system-ui;    */
}

.calculatorTabal{
    width: 50%;
    margin: 0 auto;
    margin-top: 50px;
    margin-bottom: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.calculatorTabal label {
  display: block;
  margin: 10px 0 5px;
  color: #fff;
}
.calculatorTabal input {
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
  background-color: rgba(255, 255, 255, 0.1);
  color: #fff;
  border: 1px solid #F8C912;
  border-radius: 5px;
}

.calculatorTabal button {
  margin: 20px 0;
  padding: 10px 20px;
  background-color: #F8C912;
  color: #000;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-weight: bold;
}

.calculatorTabal button:hover {
  background-color: #FFD700;
  color: #000;
}

.calculatorTabal table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

.calculatorTabal table,
th,
td {
  border: 1px solid #F8C912;
}

.calculatorTabal th,
td {
  padding: 12px;
  text-align: center;
}

.calculatorTabal th {
  background-color: rgba(255, 255, 255, 0.1);
  color: #F8C912;
  border: 1px solid #F8C912;
}

.calculatorTabal td {
    border: 1px solid #F8C912;
  background-color: rgba(0, 0, 0, 0.7);
}


@media screen and (max-width: 768px) {
    .Homeimage .homeinimage{
    width:100%;
}
.upgradepagesec{
    width: 98%;
    margin: 0 auto;
} 
.cmpboxIframe{
    top:32px;
}
.Homeimage .spantextDiv {    
    font-size: 15px;
    font-weight: 200;
    line-height: 27px;
    /* font-family: system-ui; */
}
.payButtonDiv {
    width: 100%; 
}
.imageBoxDiv .TextStroeDiv{
    width: 100%;
    padding: 10px 10px;
    text-align: center;
}
.imageBoxDiv .ImgStroeDiv{
    width: 75%;
    margin-top: 10px;
}
.upgradeLastDiv{ 
    padding: 0px 15px;
}
.calculatorTabal{
    width: 100%;
}
.calculatorTabal {
    padding: 10px;
    border: none;
  }

  .calculatorTabal input,
  .calculatorTabal button {
    padding: 10px;
    font-size: 14px;
  }

  .calculatorTabal table {
    font-size: 14px;
  }
}

