.login-signin {
    align-items:center;
    background-color: var(--light-purple);
    display: flex;
    flex-direction: column;
    /* height: 812px; */
    /* padding: 0px 58.7px;
    width: 390px; */
}

.first-time-login {
    align-items: flex-start;
    background-color: var(--light-purple);
    display: flex;
    flex-direction: column;
    height: 550px;
    padding: 157px 63px;
    width: 390px;
    
}
.telegramLoginButton{ 
    border-radius: 5px;
    background: #54a9ec;
    margin-bottom: 8px;
    display: flex;
    justify-content: center;
}
