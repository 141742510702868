@import url('https://fonts.googleapis.com/css?family=Montserrat&display=swap');


* {
	box-sizing: border-box;
}

/* body {
	background-color: #f4f4f4;
	flex-direction: column;
	font-family: 'Montserrat', sans-serif;
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100vh;
	margin: 0;
} */
/* #7b6ceb */
/* #6250e6 */
/* d8d8d8 */

.progress {
	background-color: #e9ecef	!important;
	border-radius: 20px !important;
	position: relative;
	margin: 15px 0;
	height: 30px !important;	
	/* width: 300px; */
	/* width: 70%; */
}

/* #6250e6 */

.progress-done {
	background: linear-gradient(to left, #6250e6, #a196f1);
	box-shadow: 0 3px 3px -5px #6250e6, 0 2px 5px #6250e6;
	border-radius: 20px;
	color: #fff;
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100%;
	width: 0;
	opacity: 0; 
	transition: 1s ease 0.3s;
}