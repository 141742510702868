.ticket-to-spinner {position: relative;height: 290px;}
.ticket-to-spinner .hr_ticket {transform: rotate(-90deg);}
.ticket-to-spinner .ticket_box {transform: rotate(90deg);display: flex;align-items: center;position: absolute;bottom: 204px;}
.ticket-to-spinner .ticket_box .hr_counter {font-size: 18px;font-weight: 700;position: relative;left: 18px;top: 10px;}
.ticket-to-spinner .ticket_box .ticket_content h2 {font-size: 18px;font-weight: 600;margin-bottom: 10px;white-space: nowrap;}
.ticket-to-spinner .ticket_box .ticket_content h3 {font-size: 16px;font-weight: 500;margin-bottom: 12px;}
.ticket-to-spinner .ticket_box .ticket_content {position: relative;left: 80px;top: 16px;}
.ticket-to-spinner .ticket_box .btn_buy_now {width: 114px;}
.ticket-to-spinner .ticket_box .btn_buy_now button {padding: 2px;margin: 4px 0px;width: 100px;font-size: 12px;;}
.btn_buy_now2 {width: 80px;}
.btn_buy_now2 button {padding: 2px;margin: 4px 0px;width: 100px;font-size: 12px;;}
.gold-circle-rewards {width: 100%;max-width: 375px;text-align: center;margin: 0px auto;position: relative;margin-bottom: 120px;}
.gold-circle-rewards img{width: 100%;vertical-align: middle;pointer-events: none;opacity: 0.8;}
.gold-circle-rewards .caption {background-image: url(./img/legendary.png);padding: 20px;text-align: center;display: flex;align-items: center;justify-content: center;border-radius: 16px;background-position: center;background-repeat: no-repeat;background-size: cover;flex-direction: column;position: absolute;left: 0;right: 0;width: 100%;top: 70%;transform: scale(1.1);box-shadow: 0px 1px 6px 1px #878181;}
.free-spinner-circle-rewards .caption {background-image: url(./img/normal-spinner-circle.png);}
.gold-circle-rewards .caption h3 {color: #e57a0f;font-family: "Lilita One";transition: color 1s;font-size: 25px;animation: 1s ease 0s infinite normal none running colorText;}
.gold-circle-rewards .caption .price {font-size: 32px;font-weight: 600;color: #e57a0f;}

.free-spinner-circle-rewards .caption .price {
  color: #7767f7;
}

.free-spinner-circle-rewards .caption h3{
  color: #7767f7;
  animation: 1s ease 0.5s infinite normal none running colorTextFree;
}

@media only screen and (max-width: 640px) {
  .gold-circle-rewards {width: 100%;max-width: 80%;}
  .gold-circle-rewards .caption h3 {font-size: 24px;}
  .gold-circle-rewards .caption .price {font-size: 24px;}
}

@keyframes colorTextFree {
  0% {    
    color: #B869FC;
  }
  100% {
   
   color:#ffffff;
  }  
}

.timet-box  {
  gap: 10px;
  margin-top: 10px;
  margin-bottom: 5px;
}
.timet-box .info-text {
  text-transform: capitalize;
  color: #e57a0f;
    font-size: 14px;
  font-weight: 400;
  font-family:'Lilita One'

}

.timet-box  .timer-label {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 9999px;
  text-align: center;
  background: rgb(255, 151, 0);
  font-size: 12px;
  color: white;
  padding: 2px 10px;
}



.BuyVoteTopImageBox{
  width: 250px;
  height: 400px;
  position: relative;
  border: 10px;
  display: flex;
  justify-content: end;
  flex-direction: column;
  align-items: center;
  margin:10px 20px;
  }
  .BuyVoteBoxFoxImage{
  position:absolute;
  /* border: 1px solid red !important; */
  width: 100%;
  top: 0;
  z-index: 2;
  }
  
  .BuyVoteBoxFoxImage img{
      width: 80px;
      height: 80px;
  }
  
  .BuyVoteShadowBox{
      width: 0px;
  height: 0px;
  backdrop-filter: blur(90px);
  margin: 0 auto;
  border-radius:50px ;
           
  border-radius: 50%;
  }
  
  .BuyVoteBoxForText{
      height: 360px;
      width: 100%;    
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      background: #2E1B484D;
      border-radius: 10px;
      border: 1px solid;
      border-image-source: linear-gradient(120.5deg, rgba(255, 255, 255, 0.5) 2.05%, rgba(255, 255, 255, 0) 98.2%),
      linear-gradient(120.5deg, rgba(255, 255, 255, 0) 2.05%, rgba(255, 255, 255, 0.1) 98.2%);
      border-image-slice: 1;
      backdrop-filter: blur(5px);
      padding: 10px 10px;
      z-index: 1;
      text-align: center;
  }
  
  .BuyVoteBoxForText p{
     
  }
  
  .BuyVoteBoxForText strong{
      
  }  
  
  .BuyVoteBoxForText span{
   z-index: 4;  
  }